import L from "leaflet";
import {
  LayerGroup,
  LayersControl,
  MapContainer,
  ScaleControl,
  TileLayer,
} from "react-leaflet";
import {
  Button,
  Collapse,
  DatePicker,
  Drawer,
  Flex,
  InputNumber,
  Layout,
  Modal,
  Radio,
  Select,
  Slider,
  Space,
  Spin,
  Switch,
  notification,
} from "antd";
import dayjs from "dayjs";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import "leaflet-loading";
import Control from "react-leaflet-custom-control";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import SubMenu from "../../components/SubMenu.js";
import VectorData from "../../utils/VectorData.js";
import EarthEngine from "../../utils/EarthEngine.js";
import Graph from "../../utils/Graph.js";
import JRCLayer from "../../utils/JRCLayer.js";
import {
  setLoggedIn,
  setSelected,
  setSelectedTwo,
  setselectedKey,
  setSelectedWapor,
  setshowTour,
} from "../../slices/mapView.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faCalculator,
  faCalendar,
  faChartLine,
  faCircleMinus,
  faCirclePlus,
  faCodeCompare,
  faGrip,
  faLayerGroup,
  faListCheck,
  faListOl,
  faObjectGroup,
} from "@fortawesome/free-solid-svg-icons";
import AddMask from "../../utils/AddMask.js";
import { useCallback } from "react";
import UploadShapefile from "../../utils/UploadShapefile.js";
import WebsiteTour from "../../utils/WebsiteTour.js";

import "leaflet/dist/leaflet.css";
import "leaflet-loading/src/Control.Loading.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "../../style/map.css";
import "../../style/custom.css";
import "../../style/sidebar.css";
import Indices from "../LayerPanels/Indices.js";
import DrawTools from "../DrawTools/DrawTools.js";
import SentinelLULC from "../../utils/SentinelLULC.js";
import LULCLegend from "../../utils/LULCLegend.js";
import HeaderMap from "../../components/HeaderMap.js";
import NorthArrow from "../../utils/NorthArrow.js";
import SwipeModeLayer from "../../utils/SwipeModeLayer.js";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

let minDate = dayjs("2001-01-01"); // January 1st, 2001

const indicesNames = [
  { CWD: "Crop Water Deficit" },
  { MAI: "Moisture Adequacy Index" },
  { NDVI_Anamoly: "Normalize Difference Vegetation Index" },
  { SMCI: "Soil Moisture Condition Index" },
  { TCI: "Temperature Condition Index" },
  { VCI: "Vegetation Condition Index" },
  { SMA_WAPOR: "Soil Moisture Anomalies (WaPOR)" },
  { DrySpell: "Dry Spell" },
  { PCI: "Precipitation Condition Index" },
  { SPI_CHIRPS: "Standardized Precipitation Index (CHIRPS)" },
  { SPI_ERA5L: "Standardized Precipitation Index (ERA5L)" },
  { RDI_WAPOR: "Reconnaissance Drought Index (WaPOR)" },
  { ESI_WAPOR: "Evaporative Stress Index (WaPOR)" },
  { NPP_Anamoly_WAPOR: "Net Primary Productivity (WaPOR)" },
  { VHI: "Vegetation Health Index" },
];

const headerStyle = {
  textAlign: "center",
  color: "#fff",
  background: "linear-gradient(180deg, #5C4033, #C4A484)",
  zIndex: 15000,
  height: "5em",
};
const contentStyle = {
  color: "#fff",
  background: "#fff",
};

function MapWrapper(props) {
  const { Panel } = Collapse;
  const mapRef = useRef(null);
  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, title, msg, placement) => {
    api[type]({
      message: title,
      description: msg,
      placement,
    });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    fileName,
    darkmode,
    selected,
    selectedTwo,
    selectedWapor,
    showTour,
    center,
    zoom,
    module,
    admin1,
    admin1Name,
    admin2,
    admin2Name,
  } = useSelector((state) => state);
  const [comparisonOn, setComparisonOn] = useState(false);
  const [layerURL, setLayerURL] = useState([]);
  const [graphModal, setGraphModal] = useState(false);
  const [graphOption, setGraphOption] = useState("bae");
  const [opac, setOpac] = useState(1);
  const [showOpacity, setshowOpacity] = useState(false);
  const [modalIndice, setmodalIndice] = useState("");
  const [modalMessage, setmodalMessage] = useState("");
  const [modalopen, setmodalopen] = useState(false);
  // const [infoclicked, setinfoclicked] = useState(false);
  const [color, setcolor] = useState("#000");
  const [open, setOpen] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [reset, setreset] = useState(false);
  const [applyBtn, setapplyBtn] = useState(true);
  const [graphBtn, setgraphBtn] = useState(false);
  const [showGraph, setshowGraph] = useState(false);
  const [loadlayer, setloadlayer] = useState(false);
  const [legend, setlegend] = useState(false);
  const [legendLULC, setlegendLULC] = useState(false);
  const [showprecipitation, setshowprecipitation] = useState(false);
  const [showmonths, setshowmonths] = useState(false);
  const [months, setmonths] = useState(3);
  const [precipitation, setprecipitation] = useState(2.5);
  const [layermin, setlayermin] = useState(null);
  const [layermax, setlayermax] = useState(null);
  const [calctype, setcalctype] = useState(null);
  const [layerPalette, setlayerPalette] = useState(null);
  const [layerTwoPalette, setlayerTwoPalette] = useState(null);
  const [tehsils, setTehsils] = useState([]);
  const [calcval, setcalcval] = useState("mean");
  const [startmonth, setstartmonth] = useState(1);
  const [endmonth, setendmonth] = useState(12);
  const [districts, setDistricts] = useState([]);
  const [selectedUnit, setselectedUnit] = useState(null);
  const [selectedDistrict, setselectedDistrict] = useState(null);
  const [selectedTehsil, setselectedTehsil] = useState(null);
  const [selectedMin, setSelectedMin] = useState(-3);
  const [selectedMax, setSelectedMax] = useState(3);
  const [unit, setUnit] = useState("units");
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [loggedin, setloggedin] = useState(false);
  const [startYear, setstartYear] = useState("2024");
  const [endYear, setendYear] = useState("2024");
  const [enddefaultDate, setenddefaultDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );

  const [startDate, setStartDate] = useState(dayjs("2024-01-01"));
  const [drawnFeature, setDrawnFeature] = useState([]); // To store all layers
  const [boundarySelect, setBoundarySelect] = useState(0);

  const handleStartChange = (date) => {
    if (date) {
      const newStartYear = date.year();
      setendYear(newStartYear);
      setStartDate(date);

      // Adjust the end date to match the new start year if necessary
      let newEndDate = date.year(newStartYear).month(11); // Set to December of the new start year
      const today = dayjs();

      if (newEndDate > today.endOf("month")) {
        newEndDate = dayjs().year(newStartYear).month(today.month());
      }

      setenddefaultDate(newEndDate);
    }
  };

  const onClose = () => {
    setshowGraph(false);
  };

  const getdistricts = (e) => {
    let temp = [];
    e.forEach((feat) => {
      temp.push({ value: feat.properties.name, label: feat.properties.name });
    });
    temp.sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    setDistricts(temp);
  };

  const gettehsils = (e) => {
    let temp = [];
    e.forEach((feat) => {
      temp.push({ value: feat.properties.name, label: feat.properties.name });
    });
    temp.sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    setTehsils(temp);
  };

  const clickedUnit = (e) => {
    dispatch(setshowTour(false));
    setloadlayer(false);
    setshowOpacity(false);
    setreset(true);
    if (e.level === 1) {
      setUnit("districts");
      setselectedUnit(e.name);
    }
    if (e.level === 2) {
      setUnit("tehsils");
      setselectedDistrict(e.name);
    }
    if (e.level === 3) {
      setUnit("subtehsil");
      setselectedTehsil(e.name);
    }
  };

  useEffect(() => {
    dispatch(setSelected(null));
    dispatch(setSelectedTwo(null));
    dispatch(setSelectedWapor(null));
    setcolor(!darkmode ? "#fff" : "#000");
    document.documentElement.style.setProperty(
      "--ll-color",
      !darkmode ? "#000" : "#fff"
    );
    document.documentElement.style.setProperty(
      "--ll-background-color",
      darkmode ? "#000" : "#fff"
    );
    Axios.get("../backend/logincheck", {
      headers: {
        "access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.auth) {
        setloggedin(true);
        navigate("/map");
        setLoading(false);
        dispatch(setLoggedIn(true));
      } else {
        dispatch(setLoggedIn(false));
        dispatch(setselectedKey(null));
        setLoading(true);
        setloggedin(false);
        navigate("/login");
      }
    });
  }, [darkmode, dispatch, module, navigate]);

  useEffect(() => {
    if (admin1 && admin1Name) setselectedUnit(admin1Name);
  }, []);

  const sliderChange = useCallback((e) => {
    setOpac(e);
  }, []);

  const resetAll = (e) => {
    if (e !== "draw") {
      setselectedTehsil(null);
      setUnit("units");
      setselectedUnit(null);
      setDistricts([]);
      setTehsils([]);
      setselectedDistrict(null);
      setselectedTehsil(null);
      setreset(false);
    }
    setlegend(false);
    setloadlayer(false);
    setshowGraph(false);
    setshowOpacity(false);
    dispatch(setSelected(null));
    dispatch(setSelectedTwo(null));
    setshowprecipitation(false);
    setshowmonths(false);
  };

  return (
    <>
      {contextHolder}
      <Spin
        indicator={
          <Box sx={{ width: "100%" }}>
            <img
              alt="revolving globe"
              src="https://media.tenor.com/4NKYe36DcE8AAAAj/taclan-world.gif"
              style={{ marginLeft: 40, width: 75, height: 75 }}
              spin="true"
            />
            <img
              alt="loading dots"
              src="https://media.tenor.com/mT5Timqns1sAAAAi/loading-dots-bouncing-dots.gif"
              style={{ width: 150, height: 75 }}
              spin="true"
            />
          </Box>
        }
        spinning={loading}
      >
        <Layout style={{ padding: "0", margin: "0", height: "100vh" }}>
          <Header style={headerStyle}>
            <HeaderMap heading={props.heading} loggedin={loggedin} />
          </Header>
          <Layout hasSider>
            <Sider
              trigger={null}
              collapsedWidth="0"
              width={250}
              style={{
                textAlign: "center",
                color: "#fff",
                overflowY: "scroll",
                background: color,
                borderRight: "1px solid black",
              }}
              collapsible
              collapsed={collapsed}
            >
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                <div ref={ref4}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faGrip} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Module
                  </p>
                  <Select
                    showSearch
                    placeholder="Select a module"
                    optionFilterProp="children"
                    value={module}
                    options={[
                      {
                        value: "monitoring",
                        label: "Drought Monitoring",
                      },
                    ]}
                  />
                </div>
                <Space direction="vertical" ref={ref5}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faListOl} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Selection Type
                  </p>
                  <Radio.Group
                    onChange={(e) => {
                      setBoundarySelect(e.target.value);
                    }}
                    value={boundarySelect}
                  >
                    <Space direction="vertical">
                      <Radio value={0}>Interactive</Radio>
                      <Radio value={1}>Upload</Radio>
                      <Radio value={2}>Draw</Radio>
                    </Space>
                  </Radio.Group>
                  {boundarySelect === 0 ? (
                    <>
                      <p className="sidebar-module">
                        <FontAwesomeIcon icon={faObjectGroup} />
                        &nbsp;&nbsp;&nbsp;&nbsp;Region
                      </p>
                      <Select
                        placeholder="Select a unit"
                        optionFilterProp="children"
                        onChange={(e) => {
                          setreset(true);
                          setloadlayer(false);
                          setshowOpacity(false);
                          setselectedUnit(e);
                          setselectedDistrict(null);
                          setUnit("districts");
                        }}
                        value={admin1 ? admin1Name : selectedUnit}
                        options={[
                          {
                            value: "AZAD KASHMIR",
                            label: "AZAD KASHMIR",
                            disabled: admin1 && admin1Name !== "AZAD KASHMIR",
                          },
                          {
                            value: "BALOCHISTAN",
                            label: "BALOCHISTAN",
                            disabled: admin1 && admin1Name !== "BALOCHISTAN",
                          },
                          {
                            value: "GILGIT BALTISTAN",
                            label: "GILGIT BALTISTAN",
                            disabled:
                              admin1 && admin1Name !== "GILGIT BALTISTAN",
                          },
                          {
                            value: "FEDERAL CAPITAL TERRITORY",
                            label: "FEDERAL CAPITAL TERRITORY",
                            disabled:
                              admin1 &&
                              admin1Name !== "FEDERAL CAPITAL TERRITORY",
                          },
                          {
                            value: "KHYBER PAKHTUNKHWA",
                            label: "KHYBER PAKHTUNKHWA",
                            disabled:
                              admin1 && admin1Name !== "KHYBER PAKHTUNKHWA",
                          },
                          {
                            value: "PUNJAB",
                            label: "PUNJAB",
                            disabled: admin1 && admin1Name !== "PUNJAB",
                          },
                          {
                            value: "SINDH",
                            label: "SINDH",
                            disabled: admin1 && admin1Name !== "SINDH",
                          },
                        ]}
                      />
                      {districts.length > 0 || (admin1 && admin2) ? (
                        <>
                          <Select
                            placeholder="Select a district"
                            onChange={(e) => {
                              setreset(true);
                              setloadlayer(false);
                              setshowOpacity(false);
                              setselectedDistrict(e);
                              setUnit("tehsils");
                            }}
                            disabled={admin2 ? true : false}
                            value={admin2 ? admin2Name : selectedDistrict}
                            options={districts}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {tehsils.length > 0 ? (
                        <>
                          <Select
                            placeholder="Select a tehsil"
                            onChange={(e) => {
                              setreset(true);
                              setloadlayer(false);
                              setshowOpacity(false);
                              setselectedTehsil(e);
                              setUnit("subtehsil");
                            }}
                            value={selectedTehsil}
                            options={tehsils}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {reset ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        resetAll("btn");
                      }}
                    >
                      Reset
                    </Button>
                  ) : (
                    ""
                  )}
                </Space>
                <div ref={ref6}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faObjectGroup} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Indicators
                  </p>
                  <Collapse bordered={false} accordion>
                    <Panel
                      header={
                        <Flex
                          justify="space-between"
                          style={{
                            color: selected
                              ? darkmode
                                ? "#1668dc"
                                : "#1668dc"
                              : "",
                          }}
                        >
                          <div className="column" style={{ width: "100%" }}>
                            <span>Indices</span>
                          </div>
                          <div className="column third-column">
                            <FontAwesomeIcon icon={faListCheck} />
                          </div>
                        </Flex>
                      }
                    >
                      <Indices
                        setloadlayer={setloadlayer}
                        setshowOpacity={setshowOpacity}
                        setshowGraph={setshowGraph}
                        setshowprecipitation={setshowprecipitation}
                        setshowmonths={setshowmonths}
                        setapplyBtn={setapplyBtn}
                        setgraphBtn={setgraphBtn}
                        setlegend={setlegend}
                        minDate={minDate}
                        openNotificationWithIcon={openNotificationWithIcon}
                        setmodalMessage={setmodalMessage}
                        setmodalopen={setmodalopen}
                        compare={comparisonOn}
                        setSelectedMin={setSelectedMin}
                        setSelectedMax={setSelectedMax}
                        setLayerURL={setLayerURL}
                      />
                    </Panel>
                    {/* <Panel
                      header={
                        <Flex
                          justify="space-between"
                          style={{
                            color: selectedWapor
                              ? darkmode
                                ? "#1668dc"
                                : "#1668dc"
                              : "",
                          }}
                        >
                          <div className="column" style={{ width: "100%" }}>
                            <span>WaPOR Datasets</span>
                          </div>
                          <div className="column third-column">
                            <FontAwesomeIcon icon={faWater} />
                          </div>
                        </Flex>
                      }
                    >
                      <WaporIndices
                        setloadlayer={setloadlayer}
                        setshowOpacity={setshowOpacity}
                        setshowGraph={setshowGraph}
                        setshowprecipitation={setshowprecipitation}
                        setshowmonths={setshowmonths}
                        setapplyBtn={setapplyBtn}
                        setgraphBtn={setgraphBtn}
                        setlegend={setlegend}
                        minDate={minDate}
                      />
                    </Panel> */}
                  </Collapse>
                </div>

                <div ref={ref1}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faCalendar} />
                    &nbsp;&nbsp;&nbsp;&nbsp; Start Date
                  </p>
                  <DatePicker
                    picker="month"
                    allowClear={false}
                    defaultValue={startDate}
                    disabledDate={(current) => {
                      const today = dayjs();
                      return (
                        (current && current < minDate) ||
                        (current && current > today.endOf("month"))
                      );
                    }}
                    format="MMM YYYY"
                    onChange={(e) => {
                      setStartDate(e);
                      // handleStartChange(e);
                      setrefresh(false);
                      setloadlayer(false);
                      setshowOpacity(false);
                      setshowGraph(false);

                      setstartmonth(parseInt(e.get("month") + 1));
                      setendmonth(
                        e.get("year") === dayjs().year()
                          ? parseInt(dayjs().month() + 1)
                          : 12
                      );
                      setstartYear(e.get("year"));
                    }}
                  />
                </div>
                <div ref={ref2}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faCalendar} />
                    &nbsp;&nbsp;&nbsp;&nbsp;End Date
                  </p>

                  <DatePicker
                    picker="month"
                    value={enddefaultDate}
                    disabledDate={(current) => {
                      const minDate = startDate.startOf("month"); // End date should be after the start date
                      const today = dayjs().endOf("month"); // End of the current month

                      return current && (current < minDate || current > today);
                    }}
                    format="MMM YYYY"
                    allowClear={false}
                    onChange={(e) => {
                      setrefresh(false);
                      setloadlayer(false);
                      setshowOpacity(false);
                      setshowGraph(false);

                      setendmonth(parseInt(e.get("month") + 1));
                      setendYear(e.get("year"));

                      let newEndDate = dayjs()
                        .year(e.get("year"))
                        .month(e.get("month")); // Set to the selected month and year
                      setenddefaultDate(newEndDate);
                    }}
                  />
                </div>
                {/* {selected || selectedWapor ? null : null} */}
                <div ref={ref3}>
                  <p className="sidebar-module">
                    <FontAwesomeIcon icon={faCalculator} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Aggregation
                  </p>
                  <Select
                    showSearch
                    disabled={selected === "DrySpell" ? true : false}
                    placeholder="Select a calculation value"
                    optionFilterProp="children"
                    defaultValue={calcval}
                    options={[
                      {
                        value: "mean",
                        label: "Mean",
                      },
                      {
                        value: "median",
                        label: "Median",
                      },
                      {
                        value: "min",
                        label: "Minimum",
                      },
                      {
                        value: "max",
                        label: "Maximum",
                      },
                    ]}
                    onChange={(e) => {
                      if (selected) {
                        setcalcval(e);
                        setloadlayer(false);
                        setshowOpacity(false);
                        setshowGraph(false);
                      }
                    }}
                  />
                </div>
                <div>
                  <Space direction="vertical">
                    <p className="sidebar-module">
                      <FontAwesomeIcon icon={faCircleMinus} />
                      &nbsp;&nbsp;&nbsp;&nbsp;Min
                    </p>
                    <InputNumber
                      min={-5}
                      step={0.1}
                      max={10}
                      value={selectedMin}
                      onChange={(e) => setSelectedMin(e)}
                    />
                    <p className="sidebar-module">
                      <FontAwesomeIcon icon={faCirclePlus} />
                      &nbsp;&nbsp;&nbsp;&nbsp;Max
                    </p>
                    <InputNumber
                      min={-5}
                      step={0.1}
                      max={10}
                      value={selectedMax}
                      onChange={(e) => setSelectedMax(e)}
                    />
                  </Space>
                </div>

                {layerURL.length >= 1 ? (
                  <div>
                    <p className="sidebar-module">
                      <FontAwesomeIcon icon={faCodeCompare} />
                      &nbsp;&nbsp;&nbsp;&nbsp;Compare
                    </p>
                    <Switch
                      checkedChildren={"✓"}
                      unCheckedChildren={"X"}
                      defaultChecked={comparisonOn}
                      onChange={(e) => {
                        const button =
                          document.getElementById("sm-exit-button");
                        if (button) {
                          button.click();
                        }

                        if (!e) {
                          dispatch(setSelectedTwo(null));
                          if (layerURL.length > 1) {
                            setLayerURL((prevLayerURL) =>
                              prevLayerURL.slice(0, -1)
                            );
                          }
                        }
                        setloadlayer(true);
                        setComparisonOn(e);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {showprecipitation ? (
                  <>
                    <p className="sidebar-module">
                      Precipitation Threshold (mm)
                    </p>
                    <InputNumber
                      min={1}
                      max={50}
                      step={0.5}
                      defaultValue={2.5}
                      onChange={(e) => {
                        setprecipitation(e);
                        setloadlayer(false);
                        setshowOpacity(false);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
                {showmonths ? (
                  <>
                    <p className="sidebar-module">Months</p>
                    <Select
                      placeholder="Select month"
                      onChange={(e) => {
                        setmonths(e);
                        setloadlayer(false);
                        setshowOpacity(false);
                      }}
                      value={months}
                      options={[
                        {
                          value: "1",
                          label: "1",
                        },
                        {
                          value: "3",
                          label: "3",
                        },
                        {
                          value: "6",
                          label: "6",
                        },
                        {
                          value: "12",
                          label: "12",
                        },
                      ]}
                    />
                  </>
                ) : (
                  ""
                )}

                <Space ref={ref7} direction="horizontal" size="small">
                  <Button
                    type="primary"
                    disabled={applyBtn}
                    onClick={() => {
                      if (
                        !selectedUnit &&
                        boundarySelect === 0 &&
                        (admin1 || admin2)
                      ) {
                        openNotificationWithIcon(
                          "info",
                          "Please select an administrative unit",
                          "",
                          "bottomRight"
                        );
                        return;
                      }
                      setloadlayer(true);
                      setshowOpacity(true);
                      setshowGraph(false);
                      setLoading(true);
                      setlegend(false);
                      setlayermin(null);
                      setlayermax(null);
                      setlayerPalette(null);
                      setrefresh(true);
                      setcalctype("map");
                    }}
                    icon={<FontAwesomeIcon icon={faLayerGroup} />}
                  >
                    Apply
                  </Button>
                  <Button
                    disabled={!graphBtn}
                    onClick={() => {
                      if (
                        !selectedUnit &&
                        boundarySelect === 0 &&
                        (admin1 || admin2)
                      ) {
                        openNotificationWithIcon(
                          "info",
                          "Please select an administrative unit",
                          "",
                          "bottomRight"
                        );
                        return;
                      }
                      // setGraphModal(!graphModal);

                      setGraphModal(false);
                      setcalctype("table");
                      setshowGraph(true);
                      setLoading(true);
                      setrefresh(true);
                      setlegend(false);
                      setloadlayer(false);
                      setshowOpacity(false);
                    }}
                    icon={<FontAwesomeIcon icon={faChartLine} />}
                  >
                    Graph
                  </Button>
                </Space>
              </Space>
            </Sider>
            <Content ref={ref0} style={contentStyle}>
              <MapContainer
                center={center}
                zoom={zoom}
                className="map-container"
                maxZoom={27}
                loadingControl={true}
                ref={mapRef}
              >
                <AddMask />
                {legendLULC ? (
                  <Control prepend position="bottomleft">
                    <LULCLegend legendLULC={(e) => setlegendLULC(e)} />
                  </Control>
                ) : null}
                <Control prepend position="topleft">
                  <Button
                    icon={
                      collapsed ? (
                        <FontAwesomeIcon icon={faAnglesRight} />
                      ) : (
                        <FontAwesomeIcon icon={faAnglesLeft} />
                      )
                    }
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      fontSize: "16px",
                    }}
                  />
                  {boundarySelect === 1 ? <UploadShapefile /> : null}
                  {}
                </Control>
                {boundarySelect === 2 ? (
                  <DrawTools
                    resetAll={resetAll}
                    setDrawnFeature={setDrawnFeature}
                    drawnFeature={drawnFeature}
                    setloadlayer={setloadlayer}
                    setSelected={setSelected}
                    setSelectedWapor={setSelectedWapor}
                    setreset={setreset}
                  />
                ) : null}
                <LayersControl position="topleft">
                  <LayersControl.BaseLayer
                    name="Google Satellite"
                    checked={false}
                  >
                    <LayerGroup name=" Google Satellite">
                      <TileLayer
                        maxZoom={27}
                        url="https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                      />
                      <TileLayer
                        maxZoom={27}
                        url="https://mt0.google.com/vt/lyrs=h&hl=en&x={x}&y={y}&z={z}"
                      />
                    </LayerGroup>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Google Maps">
                    <TileLayer
                      name="Google Maps"
                      maxZoom={27}
                      url="https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer
                    name="Carto Dark"
                    checked={!darkmode ? false : true}
                  >
                    <TileLayer
                      name="Carto Dark"
                      maxZoom={20}
                      url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                      className="map-tiles"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="ESRI NatGeo" checked={false}>
                    <TileLayer
                      name="ESRI NatGeo"
                      maxZoom={16}
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
                      className="map-tiles"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer
                    name="ESRI World Imagery"
                    checked={darkmode ? false : true}
                  >
                    <TileLayer
                      name="ESRI World Imagery"
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                      className="map-tiles"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Open Topomap" checked={false}>
                    <TileLayer
                      name="Open Topomap"
                      maxZoom={17}
                      url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                      className="map-tiles"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.Overlay
                    checked={false}
                    name="Sentinel LULC"
                    onChange={(e) => {
                      const isChecked = e.target.checked; // Detect if the layer is checked
                      setlegendLULC(isChecked);

                      console.log(
                        `Sentinel LULC is now ${isChecked ? "ON" : "OFF"}`
                      );
                    }}
                  >
                    {loggedin ? (
                      <SentinelLULC
                        error={(msg) => {
                          openNotificationWithIcon(
                            "error",
                            msg,
                            "",
                            "bottomRight"
                          );
                          setLoading(false);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </LayersControl.Overlay>
                  <LayersControl.Overlay checked name="Earth Engine Layer">
                    {loadlayer ? (
                      <>
                        <EarthEngine
                          res={(val) => {
                            setLayerURL((prevLayerURL) => {
                              const updatedLayerURL =
                                prevLayerURL.length === 2
                                  ? prevLayerURL.slice(0, -1)
                                  : prevLayerURL;

                              // Append the new value
                              return [...updatedLayerURL, val];
                            });

                            // setapplyBtn(true);
                            setshowOpacity(true);
                            if (selected) setlayerPalette(val.palette);
                            if (selectedTwo) setlayerTwoPalette(val.palette);
                            setlayermax(val.max);
                            setlayermin(val.min);
                            setlegend(true);
                            selected === "DrySpell"
                              ? setgraphBtn(false)
                              : setgraphBtn(true);
                            setrefresh(false);
                          }}
                          error={(msg) => {
                            openNotificationWithIcon(
                              "error",
                              msg,
                              "",
                              "bottomRight"
                            );
                            setLoading(false);
                            setlegend(false);
                          }}
                          boundarySelect={boundarySelect}
                          fileName={boundarySelect === 1 ? fileName : null}
                          drawnFeature={
                            boundarySelect === 2 ? drawnFeature : null
                          }
                          opac={opac}
                          loading={(e) => setLoading(e)}
                          precipitation={precipitation}
                          months={months}
                          calcval={calcval}
                          refresh={refresh}
                          startmonth={startmonth}
                          endmonth={endmonth}
                          startyear={startYear}
                          endyear={endYear}
                          indicator={selected ? selected : selectedWapor}
                          indicatorTwo={
                            selectedTwo ? selectedTwo : selectedWapor
                          }
                          compare={comparisonOn}
                          selectedUnit={selectedUnit}
                          selectedDistrict={selectedDistrict}
                          selectedTehsil={selectedTehsil}
                          calctype={calctype}
                          selectedMin={selectedMin}
                          selectedMax={selectedMax}
                        />
                        {legend && (selected || selectedWapor) ? (
                          <>
                            <div
                              style={{
                                width: "auto",
                                background: !darkmode ? "white" : "black",
                                borderRadius: "5px",
                                padding: "5px",
                                position: "fixed",
                                bottom: "15px",
                                right: "15px",
                                zIndex: 5000,
                                color: !darkmode ? "black" : "white",
                                textAlign: "center",
                              }}
                            >
                              <span className="font-bold text-[18px] bg-gradient-to-r from-white to-transparent bg-clip-text text-transparent">
                                {indicesNames.find(
                                  (obj) => obj[selected || selectedWapor]
                                )
                                  ? indicesNames.find(
                                      (obj) => obj[selected || selectedWapor]
                                    )[selected || selectedWapor]
                                  : "Not Found"}
                              </span>
                              <br />

                              <span>{/* {startdate} to {enddate} */}</span>
                              <div
                                style={{
                                  width: "auto",
                                  display: "flex",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  zIndex: 5000,
                                  color: !darkmode ? "black" : "white",
                                }}
                              >
                                <span style={{ marginRight: "5px" }}>
                                  {layermin}
                                </span>
                                {layerPalette ? (
                                  <div
                                    style={{
                                      width: "250px",
                                      height: "15px",
                                      background: `linear-gradient(to right, ${layerPalette
                                        .map((palette) => `#${palette}`)
                                        .join(", ")})`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                <span style={{ marginLeft: "5px" }}>
                                  {layermax}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {comparisonOn && selectedTwo ? (
                          <>
                            <div
                              style={{
                                width: "auto",
                                background: !darkmode ? "white" : "black",
                                borderRadius: "5px",
                                padding: "5px",
                                position: "fixed",
                                bottom: "15px",
                                left: "255px",
                                zIndex: 5000,
                                color: !darkmode ? "black" : "white",
                                textAlign: "center",
                              }}
                            >
                              <span className="font-bold text-[18px] bg-gradient-to-r from-white to-transparent bg-clip-text text-transparent">
                                {indicesNames.find((obj) => obj[selectedTwo])
                                  ? indicesNames.find(
                                      (obj) => obj[selectedTwo]
                                    )[selectedTwo]
                                  : "Not Found"}
                              </span>
                              <br />

                              <span>{/* {startdate} to {enddate} */}</span>
                              <div
                                style={{
                                  width: "auto",
                                  display: "flex",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  zIndex: 5000,
                                  color: !darkmode ? "black" : "white",
                                }}
                              >
                                <span style={{ marginRight: "5px" }}>
                                  {layermin}
                                </span>
                                {layerTwoPalette ? (
                                  <div
                                    style={{
                                      width: "250px",
                                      height: "15px",
                                      background: `linear-gradient(to right, ${layerTwoPalette
                                        .map((palette) => `#${palette}`)
                                        .join(", ")})`,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                <span style={{ marginLeft: "5px" }}>
                                  {layermax}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </LayersControl.Overlay>
                  <LayersControl.Overlay checked name="Boundary">
                    {loggedin && boundarySelect === 0 ? (
                      <VectorData
                        adminUnit={unit}
                        clickedUnit={clickedUnit}
                        selectedUnit={selectedUnit}
                        selectedDistrict={selectedDistrict}
                        selectedTehsil={selectedTehsil}
                        getdistricts={getdistricts}
                        gettehsils={gettehsils}
                        layers={layerURL}
                      />
                    ) : (
                      ""
                    )}
                  </LayersControl.Overlay>
                  <LayersControl.Overlay checked={true} name="Surface Water">
                    {loggedin ? (
                      <JRCLayer
                        error={(msg) => {
                          openNotificationWithIcon(
                            "error",
                            msg,
                            "",
                            "bottomRight"
                          );
                          setLoading(false);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </LayersControl.Overlay>
                </LayersControl>
                {showGraph ? (
                  <div
                    style={{
                      zIndex: 5000,
                      overflow: "hidden",
                      height: "250px",
                    }}
                  >
                    <Drawer
                      mask={false}
                      placement="bottom"
                      height={"auto"}
                      onClose={onClose}
                      open={!open}
                      getContainer={false}
                    >
                      <Graph
                        error={(msg) => {
                          openNotificationWithIcon(
                            "error",
                            msg,
                            "",
                            "bottomRight"
                          );
                          setLoading(false);
                          setshowGraph(false);
                        }}
                        graphOption={graphOption}
                        loading={(e) => setLoading(e)}
                        drawnFeature={
                          boundarySelect === 2 ? drawnFeature : null
                        }
                        precipitation={precipitation}
                        months={months}
                        calcval={calcval}
                        refresh={refresh}
                        startmonth={startmonth}
                        endmonth={endmonth}
                        startyear={startYear}
                        endyear={endYear}
                        indicator={selected ? selected : selectedWapor}
                        selectedUnit={selectedUnit}
                        selectedDistrict={selectedDistrict}
                        selectedTehsil={selectedTehsil}
                        calctype={calctype}
                        boundarySelect={boundarySelect}
                        selectedMin={selectedMin}
                        selectedMax={selectedMax}
                      />
                    </Drawer>
                  </div>
                ) : (
                  ""
                )}
                {/* Add Scalebar */}
                <ScaleControl position="bottomleft" />
                {/* Add North Arrow */}
                <NorthArrow />
                {/* Add Swipe Control */}
                {selected && selectedTwo ? (
                  <SwipeModeLayer
                    layers={layerURL}
                    leftLayerName={selected}
                    rightLayerName={selectedTwo}
                  />
                ) : null}
              </MapContainer>
              {showOpacity ? (
                <div
                  style={{
                    position: "fixed",
                    zIndex: 500,
                    bottom: "10em",
                    right: ".6em",
                    height: "10em",
                  }}
                >
                  <Slider
                    tooltip={(value) => `${value}%`}
                    marks
                    min={0}
                    max={1}
                    step={0.1}
                    vertical
                    value={opac}
                    onChange={(e) => sliderChange(e)}
                  />
                </div>
              ) : (
                ""
              )}
            </Content>
          </Layout>
        </Layout>
      </Spin>
      <Modal
        open={modalopen}
        title={modalIndice}
        onCancel={() => setmodalopen(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => setmodalopen(false)}
          >
            Close
          </Button>,
        ]}
      >
        {modalMessage}
      </Modal>
      {showTour ? (
        <WebsiteTour
          ref0={ref0}
          ref1={ref1}
          ref2={ref2}
          ref3={ref3}
          ref4={ref4}
          ref5={ref5}
          ref6={ref6}
          ref7={ref7}
        />
      ) : (
        ""
      )}
      <Modal
        title="Graph Data"
        open={graphModal}
        onOk={() => {
          setGraphModal(false);
          setcalctype("table");
          setshowGraph(true);
          setLoading(true);
          setrefresh(true);
          setlegend(false);
          setloadlayer(false);
          setshowOpacity(false);
        }}
        onCancel={(e) => setGraphModal(!graphModal)}
      >
        <Radio.Group
          onChange={(e) => {
            setshowGraph(false);
            setGraphOption(e.target.value);
          }}
          value={graphOption}
        >
          <Radio value="bae">By Analysis period</Radio>
          <Radio value="bts">By Time-Series</Radio>
        </Radio.Group>
      </Modal>
    </>
  );
}

export default MapWrapper;
